/* global successExitUrl:true, invalidExitUrl:true */

if (typeof successExitUrl === 'undefined') {
    successExitUrl = '/resources';
}

if (typeof invalidExitUrl === 'undefined') {
    invalidExitUrl = '/resources';
}

var successResponseCallback = function() {
    window.location = '/resources';
};

var invalidResponseCallback = function() {
    window.location = '/resources';
};

var offersRedirect = function() {
    window.location = '/resources';
};

var generatePostData = function(formId) {
    if (formId === null || formId === undefined) {
        formId = 'main-form';
    }

    var dataToPost = {};
    $('#' + formId + ' :input').each(function(i, el) {
        el = $(el);
        if (el.attr('type') === 'radio' || el.attr('type') === 'checkbox') {
            if (!el.is(':checked')) {
                return;
            }
        }
        dataToPost[el.attr('name')] = el.val();
    });

    return dataToPost;
};

var stepHandler = function(step, maxStep) {
    if (step === maxStep) {
        setTimeout(function() {
            offersRedirect();
        }, 150);
    } else {
        var nextStep = parseInt(step + 1);
        $('*[step="' + step + '"]').addClass('hidden');
        $('*[step="' + nextStep + '"]').removeClass('hidden');
    }
};

var processForm = function() {
    var postData = generatePostData();

    $('#leadresponse_container').load('/process', postData, function() {
        var leadResponse = $('input#avt_lead_response').val();
        if (leadResponse === 'success') {
            successResponseCallback();
        } else {
            invalidResponseCallback();
        }
    });
};

var processOfferForm = function(formId, maxStep) {
    var postData = generatePostData(formId);
    var step = parseInt($('#' + formId).attr('form-step'));
    $.ajax({
        url: $('#' + formId).attr('action'),
        type: 'POST',
        dataType: 'json',
        data: postData
    }).always(function() {
        setTimeout(function() {
            stepHandler(step, maxStep);
        }, 1200);
    });
};

$(document).ready(function() {
    $('input').blur(function() {
        $(this)
            .parsley()
            .validate();
    });

    var maxStep = 0;
    $('form').each(function(i, el) {
        maxStep++;
        $(el).attr('form-step', i + 1);
        $(el)
            .closest('[id*=questions]')
            .attr('step', i + 1);
    });

    $('.next-btn').on('click', function(e) {
        e.preventDefault();
        var step = parseInt(
            $(this)
                .closest('form')
                .attr('form-step')
        );
        stepHandler(step, maxStep);
    });

    $('.btn--yes').on('click', function() {
        $(this)
            .closest('form')
            .children('.form-steps')
            .removeClass('hidden');
    });

    $('.form-submit').on('click', function(e) {
        e.preventDefault();

        var form = $(this).closest('form');
        var formId = form.attr('id');

        form.parsley().validate();

            if (form.parsley().isValid() === true) {
                $(this).attr('disabled', true);
                processForm();
                return;
            }
    });

    $('*[bind]').on('change keyup', function() {
        var to_bind = $(this).attr('bind');
        $("*[bind='" + to_bind + "']").val($(this).val());
    });

    $('label.btn').on('click', function() {
        $(this)
            .siblings()
            .parsley()
            .validate();
    });

    $('footer a').click(function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var day = new Date();
        var id = day.getTime();
        window.open(
            $(this).attr('href'),
            id,
            'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=400,height=300,left=500,top=500'
        );
        return false;
    });
});
