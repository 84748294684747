/* global ga:true, gaConfig:true */
$(document).ready(function() {

    var events = [];
    
    // Defined in offers.html and dependant on APPLICATION_ENV
    // Defaults to the Tracking Playground property if undefined
    if (typeof gaConfig === 'undefined') {
        gaConfig = 'UA-140526730-1';
    }

    if (typeof ga !== 'undefined') {

        ga('create', gaConfig);

        $(window).on('beforeunload pagehide', function() {
            events.forEach(function(i) {
                ga('send', {
                    'hitType': 'event',
                    'eventCategory': i['Event Category'],
                    'eventAction': i['Event Category'] + ' - ' + i['Event Action']
                });
            });
        });
    }

    /*
        This function checks to see if an Event Category and Event Action
        has been created already for the button actions on the form. If a record
        exists already, this will overwrite the existing value with the most recently
        performed one. Example: A user gets to the PI form and hits Yes but then hits
        No or Skip. The last action will be the one that's stored and sent to GA.
    */
    var eventsHandler = function(event) {
        for (var i = 0; i < events.length; i++) {
            if (events[i]['Event Category'] === event['Event Category'] && 
                events[i]['Event Label'] === event['Event Label']) {
                    events.splice(i, 1);
                    events.push(event);
                    return false;
            }
        }
        events.push(event);
    };

    $('.track-btn').on('click', function() {
        var clickType = $(this).siblings().attr('value');
        var event = {
            "Event Category": $(this).closest('.step').attr('data-name'),
            "Event Action": clickType,
            "Event Label": "Click"
        };
        eventsHandler(event);
    });

    // watching each form and storing the event
    // to the events array when a user views each one
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.attributeName === 'class') {
                var attributeValue = $(mutation.target).prop(mutation.attributeName);
                var formName, event;
                if (attributeValue === 'banner-wrapper step') {
                    formName = $(mutation.target).attr('data-name');
                    event = {
                        "Event Category": formName,
                        "Event Action": "Form Load"
                    };
                    events.push(event);
                } else if (attributeValue === 'form-step form-steps') {
                    formName = $(mutation.target).closest('.step').attr('data-name');
                    event = {
                        "Event Category": formName,
                        "Event Action": "Form View"
                    };
                    events.push(event);
                }
            }
        });
    });

    var config = {
        attributes: true
    };

    $('.step, .form-steps').each(function(i, el) {
        var id = $(el).attr('id');
        var target = document.querySelector('#' + id);
        
        observer.observe(target, config);
    }).promise().done(function() {
        // removing hidden class from first step after observers have been set
        // in order to trigger a view event
        $('*[step="1"]').removeClass('hidden');
    });
});